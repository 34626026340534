<script
	lang="ts"
	context="module"
>
	export type AdditionalInfoType = 'Basic Info' | 'Components' | 'Custom Fields' | 'Attachments' | 'Messages'

	//end of context=module
</script>

<script lang="ts">
	import type { Vehicle } from './results.ts'
	import type { SvelteAsr } from 'types/common'

	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import VehicleInfo from 'components/VehicleInfo.svelte'
	import VehicleAttachments from 'components/ItemDetailAttachments.svelte'
	import VehicleComponents from './VehicleComponents.svelte'
	import InventoryCustomFields from 'components/InventoryCustomFields.svelte'
	// import PartMessages from './PartMessages.svelte'

	let vehicleAttachments: VehicleAttachments | undefined
	export let asr: SvelteAsr
	export let item: Vehicle //required
	export let additionalInfoType: AdditionalInfoType = 'Basic Info'
	const additionalInfoTypeList: AdditionalInfoType[] = ['Basic Info', 'Components', 'Custom Fields', 'Attachments'] //Skipping 'Messages' for now
</script>

<div class="d-flex justify-content-between align-items-end mb-2">
	<Select
		label="Vehicle Information"
		showEmptyOption={false}
		bind:value={additionalInfoType}
	>
		{#each additionalInfoTypeList as additionalInfoTypeItem}
			<option value={additionalInfoTypeItem}>{additionalInfoTypeItem}</option>
		{/each}
	</Select>
	<Button
		outline
		size="sm"
		iconClass="pencil"
		class="mb-1 ml-2"
		href={asr.makePath('app.vehicle', { vehicleId: item.id, storeId: item.storeId })}>Edit Vehicle</Button
	>
</div>

<!-- Ensure components get rerendered when the item changes. Fixes an issue with attachments -->
{#key item.id}
	{#if additionalInfoType === 'Basic Info'}
		<VehicleInfo
			vehicleId={item.id}
			{asr}
			on:imageClicked={() => (additionalInfoType = 'Attachments')}
			showEditButton={false}
		/>
		<!--
		{:else if additionalInfoType === 'Messages'}
			<PartMessages inventoryId={item.id} />
		-->
	{:else if additionalInfoType === 'Custom Fields'}
		<InventoryCustomFields
			inventoryId={item.wholeUnit.id}
			vehicle
		/>
	{:else if additionalInfoType === 'Components'}
		<VehicleComponents
			vehicleId={item.id}
			{asr}
		/>
	{:else if additionalInfoType === 'Attachments'}
		<VehicleAttachments
			attachments={item?.attachments ?? []}
			bind:this={vehicleAttachments}
		/>
	{/if}
{/key}
