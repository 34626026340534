<script lang="ts">
	import type { Customer, CustomerType, PaymentMethod, SalesOrderTerm, SalesPerson, State, TaxItem, Store, CustomerAttachment, CustomerAlternateAddress } from 'utility/customer-helper'
	import type { CustomerAddressUpdate, CustomerAttachmentsUpdate, CustomerUpdate, NewCustomer, NewCustomerAddress, TaxExemptionUpdate } from '$houdini'
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { Merge } from 'type-fest'

	import { customerBalanceStatusMap, formatCustomerBalance } from 'utility/customer-helper'
	import { getContext, onMount, setContext, type ComponentProps } from 'svelte'
	import makeCrudStore from '@isoftdata/svelte-store-crud'
	import { hasPermission } from 'utility/permission'
	import { graphql } from '$houdini'
	import session from 'stores/session'

	import Attachments from '@isoftdata/svelte-attachments'
	import Button from '@isoftdata/svelte-button'
	import Address from './Address.svelte'
	import Additional from './Additional.svelte'
	import Dropdown, { DropdownItem } from '@isoftdata/svelte-dropdown'
	import NavTabBar from '@isoftdata/svelte-nav-tab-bar'
	import PaymentAndTax from './PaymentAndTax.svelte'
	import Sales from './Sales.svelte'
	import { DropdownCheckboxItem } from '@isoftdata/svelte-context-menu'
	import userLocalWritable from 'stores/user-local-writable'
	import CustomerSearchModal from 'components/CustomerSearchModal.svelte'
	import StateCardHeader from 'components/StateCardHeader.svelte'

	type DeepNonNullRequired<T> = Required<T> & {
		[K in keyof Required<T>]: Exclude<Required<T>[K], null>
	}
	type AttachmentsForSave = DeepNonNullRequired<CustomerAttachmentsUpdate>

	const mediator = getContext<Mediator>('mediator')

	// #region crud stores
	const alternateAddressCrudStore = makeCrudStore<CustomerAlternateAddress, 'uuid'>('uuid')
	setContext('alternateAddressCrudStore', alternateAddressCrudStore)
	const paymentMethodCrudStore = makeCrudStore<Customer['allowedPaymentMethods'][number], 'id'>('id')
	setContext('paymentMethodCrudStore', paymentMethodCrudStore)
	const taxExemptionCrudStore = makeCrudStore<Customer['taxExemptions'][number], 'uuid'>('uuid')
	setContext('taxExemptionCrudStore', taxExemptionCrudStore)
	// #endregion crud stores
	// TODO: This should get a user setting, but it doesn't have one right now, so I'm just doing this for the time being.
	const clearScreenOnSave = userLocalWritable($session.userAccountId, 'clear-customer-screen-on-save', false)

	export let asr: SvelteAsr
	export let customer: Customer
	export let attachments: Array<CustomerAttachment>
	export let states: State[]
	export let paymentMethods: PaymentMethod[]
	export let salesPersonList: SalesPerson[]
	export let taxItems: TaxItem[]
	export let salesOrderTerms: SalesOrderTerm[]
	export let customerTypeList: CustomerType[]
	export let stores: Store[]
	export let tab: 'address' | 'additional' | 'attachments' | 'paymentAndTax' = 'address'

	const tabs: ComponentProps<NavTabBar>['tabs'] = [
		{
			name: 'address',
			title: 'Address Info',
		},
		{
			name: 'additional',
			title: 'Additional Info',
		},
		{
			name: 'attachments',
			title: 'Attachments',
		},
		{
			name: 'paymentAndTax',
			title: 'Payment/Tax Info',
		},
		{
			name: 'sales',
			title: 'Sales',
		},
	]

	let customerChanged: boolean
	let viewOnlyMode = false
	let isSaving = false
	let customerSearchModal: CustomerSearchModal | undefined = undefined

	$: missingRequiredFieldsList = getMissingRequiredFields(customer)
	$: viewOnlyMode = !hasPermission('CUSTOMERS_CAN_EDIT_CUSTOMERS', customer.store?.id)

	export function canLeaveState(): boolean {
		// If the customer has no changes, we can leave the state
		return !customerChanged
	}

	function getMissingRequiredFields(customer: Customer) {
		const missingFields: string[] = []

		const requiredFieldsToCheck: Array<{
			name: string
			missingRequiredField: boolean | null | undefined
		}> = [
			{
				name: 'Company Name',
				missingRequiredField: !customer.companyName && !customer.contactName,
			},
			{
				name: 'Contact Name',
				missingRequiredField: !customer.contactName && !customer.companyName,
			},
			{
				name: 'Bill Delivery Method',
				missingRequiredField: !customer.billDeliveryMethod,
			},
			{
				name: 'Store',
				missingRequiredField: !customer.store,
			},
			{
				name: 'Salesperson',
				missingRequiredField: !customer.salesPerson,
			},
		]

		for (const field of requiredFieldsToCheck) {
			if (field.missingRequiredField) {
				missingFields.push(field.name)
			}
		}

		return missingFields.join(', ')
	}

	// #region saving customer
	function formatAlternateAddressForCreate(address: CustomerAlternateAddress): NewCustomerAddress {
		return {
			city: address.addressData.city,
			companyName: address.addressData.companyName,
			contactName: address.addressData.contactName,
			country: address.addressData.country,
			department: address.addressData.department,
			email: address.addressData.email,
			faxNumber: address.addressData.faxNumber,
			primary: address.primary,
			label: address.label,
			mobilePhoneNumber: address.addressData.mobilePhoneNumber,
			notes: address.addressData.notes,
			phoneNumber: address.addressData.phoneNumber,
			state: address.addressData.state,
			street: address.addressData.street,
			zip: address.addressData.zip,
			storeId: address.addressData.store ? address.addressData.store.id : undefined,
			salesPersonId: address.addressData.salesPerson ? address.addressData.salesPerson.id : undefined,
			validated: address.addressData.validated,
		}
	}

	function getGenericCustomerForSave(theCustomer = customer): Omit<CustomerUpdate, 'id' | 'companyName' | 'contactName' | 'defaultPriceType'> {
		const optionValuesToSave = theCustomer.optionValues
			.filter(optionValue => optionValue.value)
			.map(optionValue => ({
				optionId: optionValue.option.id,
				value: optionValue.value,
			}))

		return {
			acceptsBackOrders: theCustomer.acceptsBackOrders,
			acceptsPartialShipments: theCustomer.acceptsPartialShipments,
			accountLimit: theCustomer.accountLimit,
			active: theCustomer.active,
			billDeliveryMethod: theCustomer.billDeliveryMethod,
			billingAddress: {
				address: theCustomer.billingAddress.address1,
				city: theCustomer.billingAddress.city,
				state: theCustomer.billingAddress.state,
				zip: theCustomer.billingAddress.zip,
				country: theCustomer.billingAddress.country,
				validated: theCustomer.billingAddressValidated,
			},
			blanketPurchaseOrderNumber: theCustomer.blanketPurchaseOrderNumber,
			blanketPurchaseOrderNumberExpiration: theCustomer.blanketPurchaseOrderNumberExpiration,
			cashOnly: theCustomer.cashOnly,
			customLaborRate: theCustomer.customLaborRate,
			defaultPaymentMethodId: theCustomer.defaultPaymentMethod ? theCustomer.defaultPaymentMethod.id : null,
			defaultPricePercentage: theCustomer.defaultPricePercentage,
			defaultTaxItemId: theCustomer.defaultTaxItem ? theCustomer.defaultTaxItem.id : undefined,
			defaultTermId: theCustomer.defaultTerm ? theCustomer.defaultTerm.id : undefined,
			driverLicenseNumber: theCustomer.driverLicenseNumber,
			email: theCustomer.email,
			faxNumber: theCustomer.faxNumber,
			laborRateType: theCustomer.laborRateType,
			mobilePhoneNumber: theCustomer.mobilePhoneNumber,
			notes: theCustomer.notes,
			phoneNumber: theCustomer.phoneNumber,
			requirePurchaseOrderNumber: theCustomer.requirePurchaseOrderNumber,
			salespersonId: theCustomer.salesPerson ? theCustomer.salesPerson.id : undefined,
			storeId: theCustomer.store ? theCustomer.store.id : null,
			type: theCustomer.type,
			webAddress: theCustomer.webAddress,
			addresses: {
				create: alternateAddressCrudStore.createdValues.reduce((acc: NewCustomerAddress[], address) => {
					if (address.label) {
						acc.push(formatAlternateAddressForCreate(address))
					}
					return acc
				}, []),
				remove: alternateAddressCrudStore.deletedValues.reduce((acc: number[], address) => {
					if (address.id) {
						acc.push(address.id)
					}
					return acc
				}, []),
				update: alternateAddressCrudStore.updatedValues.reduce((acc: CustomerAddressUpdate[], address) => {
					if (address.id) {
						acc.push({
							id: address.id,
							...formatAlternateAddressForCreate(address),
						})
					}
					return acc
				}, []),
			},
			allowedPaymentMethods: {
				create: paymentMethodCrudStore.createdValues.map(paymentMethod => paymentMethod.id),
				remove: paymentMethodCrudStore.deletedValues.map(paymentMethod => paymentMethod.id),
			},
			attachments: attachments.reduce(
				({ create, remove, update }: AttachmentsForSave, attachment) => {
					if (attachment.action === 'CREATE' && attachment.File) {
						create.push({
							file: attachment.File,
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'CREATE' && attachment.fileId) {
						create.push({
							fileId: attachment.fileId,
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'UPDATE' && attachment.customerFileId) {
						update.push({
							id: attachment.customerFileId,
							public: attachment.public,
							rank: attachment.rank,
						})
					} else if (attachment.action === 'DELETE' && attachment.customerFileId) {
						remove.push(attachment.customerFileId)
					}

					return { create, remove, update }
				},
				{ create: [], remove: [], update: [] },
			),
			optionValues: optionValuesToSave,
			taxExemptions: {
				create: taxExemptionCrudStore.createdValues.map(taxExemption => ({
					expiration: taxExemption.expirationDate,
					notes: taxExemption.notes,
					number: taxExemption.number,
					state: taxExemption.state,
				})),
				remove: taxExemptionCrudStore.deletedValues.reduce((acc: number[], taxExemption) => {
					if (taxExemption.id) {
						acc.push(taxExemption.id)
					}
					return acc
				}, []),
				update: taxExemptionCrudStore.updatedValues.reduce((acc: TaxExemptionUpdate[], taxExemption) => {
					if (taxExemption.id) {
						acc.push({
							id: taxExemption.id,
							expiration: taxExemption.expirationDate,
							notes: taxExemption.notes,
							number: taxExemption.number,
							state: taxExemption.state,
						})
					}
					return acc
				}, []),
			},
		}
	}

	function getNewCustomerForSave(customerToSave: Customer): NewCustomer {
		const customerForSave = getGenericCustomerForSave(customerToSave)

		return {
			...customerForSave,
			companyName: customerToSave.companyName,
			contactName: customerToSave.contactName,
			defaultPriceType: customerToSave.defaultPriceType,
			attachments: customerForSave.attachments?.create,
			addresses: customerForSave.addresses?.create,
			allowedPaymentMethods: customerForSave.allowedPaymentMethods?.create,
			taxExemptions: customerForSave.taxExemptions?.create,
		}
	}

	function getUpdateCustomerForSave(customerToSave: Merge<Customer, { id: number }>): CustomerUpdate {
		const customerForSave = getGenericCustomerForSave(customerToSave)

		return {
			...customerForSave,
			id: customerToSave.id,
		}
	}

	async function saveCustomer(saveAndNew = $clearScreenOnSave) {
		isSaving = true
		const customerId = customer.id
		try {
			let savedCustomer: { id: number } | null = null
			if (customerId) {
				const customerToSave = {
					...customer,
					id: customerId,
				}
				const customerInputToSave = getUpdateCustomerForSave(customerToSave)
				const { data } = await updateCustomerMutation.mutate({ input: customerInputToSave })
				savedCustomer = data?.updateCustomer ?? null
			} else {
				const customerInputToSave = getNewCustomerForSave(customer)
				const { data } = await createCustomerMutation.mutate({ input: customerInputToSave })
				savedCustomer = data?.createCustomer ?? null
			}

			if (!savedCustomer) {
				throw new Error('No data returned from the server')
			}

			mediator.publish('showMessage', {
				type: 'success',
				heading: 'Saved!',
				message: 'Customer has been saved successfully',
				time: 10,
			})
			customerChanged = false
			asr.go(
				null,
				{
					customerId: saveAndNew ? null : savedCustomer.id,
					lastResetTime: Date.now(),
					tab,
				},
				{ replace: true },
			)
		} catch (error: any) {
			console.error(error)
			return mediator.publish('showMessage', {
				type: 'danger',
				heading: 'Failed To Save Customer',
				message: error.message,
				time: false,
			})
		} finally {
			isSaving = false
		}
	}
	// #endregion saving customer

	const createCustomerMutation = graphql(`
		mutation CreateCustomer($input: NewCustomer!) {
			createCustomer(input: $input) {
				id
			}
		}
	`)

	const updateCustomerMutation = graphql(`
		mutation UpdateCustomer($input: CustomerUpdate!) {
			updateCustomer(input: $input) {
				id
			}
		}
	`)

	onMount(() => {
		if (customer.id) {
			mediator.publish('activity', {
				title: customer.companyName ?? customer.contactName,
				type: 'CUSTOMER',
				route: 'app.customer',
				name: 'Part',
				parameters: {
					customerId: customer.id?.toString(),
				},
				icon: 'user',
			})
		}
	})
</script>

<div class="card">
	<StateCardHeader
		icon="user"
		title={customer.companyName || 'New Customer'}
	>
		<small class="{customerBalanceStatusMap[customer.balanceStatus].textColor} d-block font-weight-bold mb-2">Balance: {formatCustomerBalance(customer.balance, false)}</small>
		<NavTabBar
			{tabs}
			bind:selectedTab={tab}
			on:tabChange={event => asr.go(null, { tab: event.detail }, { inherit: true })}
		>
			<svelte:fragment slot="append">
				{#if missingRequiredFieldsList}
					<span class="text-danger mb-2 mr-2 ml-auto mt-auto">Missing required fields: {missingRequiredFieldsList}</span>
				{/if}
			</svelte:fragment>
		</NavTabBar>
		<svelte:fragment slot="title">
			{#if customer.contactName}
				<small class="text-muted"> ({customer.contactName})</small>
			{/if}
		</svelte:fragment>
		<svelte:fragment slot="right">
			<div
				class="d-flex"
				style="gap: 0.25rem;"
			>
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					disabled={!hasPermission('CUSTOMERS_CAN_ADD', customer.store?.id)}
					on:click={() => asr.go(null, { lastResetTime: Date.now() })}
				>
					New Customer
				</Button>
				<Button
					outline
					size="sm"
					iconClass="magnifying-glass"
					on:click={() => customerSearchModal?.show()}
				></Button>
				<Dropdown
					split
					menuItemClickCloses={false}
					size="sm"
					iconClass="floppy-disk"
					disabled={!customerChanged}
					isLoading={isSaving}
					on:click={() => saveCustomer()}
				>
					Save Customer
					<svelte:fragment slot="dropdownItems">
						<h6 class="dropdown-header">Save and...</h6>
						<DropdownCheckboxItem bind:checked={$clearScreenOnSave}>Start New Customer (Clear Screen)</DropdownCheckboxItem>
					</svelte:fragment>
				</Dropdown>
			</div>
		</svelte:fragment>
	</StateCardHeader>

	<div class="card-body">
		{#if tab == 'address'}
			<Address
				{states}
				{stores}
				{salesPersonList}
				{viewOnlyMode}
				bind:customer
				bind:customerChanged
			/>
		{:else if tab == 'additional'}
			<Additional
				{viewOnlyMode}
				bind:customer
				bind:customerTypeList
				bind:customerChanged
			/>
		{:else if tab == 'attachments'}
			<Attachments
				bind:fileList={attachments}
				modificationDisabled={viewOnlyMode}
				on:filesAdded={() => (customerChanged = true)}
				on:filesDeleted={() => (customerChanged = true)}
				on:publicChange={() => (customerChanged = true)}
				on:rankChange={() => (customerChanged = true)}
			/>
		{:else if tab == 'paymentAndTax'}
			<PaymentAndTax
				{states}
				{taxItems}
				{paymentMethods}
				{salesOrderTerms}
				{viewOnlyMode}
				bind:customer
				bind:customerChanged
			/>
		{:else if tab == 'sales'}
			<Sales
				{asr}
				bind:customer
			/>
		{/if}
	</div>
</div>

<CustomerSearchModal
	chooseItem={item => asr.go(null, { customerId: item.id })}
	bind:this={customerSearchModal}
></CustomerSearchModal>
