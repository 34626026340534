export default {
    "name": "InventoryCustomFields",
    "kind": "HoudiniQuery",
    "hash": "9f87a5f09e86a7f83c066353e74ae3bb6ce66eeb2224bdf8b774814976b2261b",

    "raw": `query InventoryCustomFields($inventoryId: UInt!) {
  inventory(id: $inventoryId) {
    options {
      id
      name
      rank
    }
    serials {
      number
      optionValues {
        optionId
        value
        id
        inventoryId
      }
      id
    }
    optionValues {
      optionId
      value
      id
      inventoryId
    }
    serialized
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $inventoryId)",

                "selection": {
                    "fields": {
                        "options": {
                            "type": "InventoryOption",
                            "keyRaw": "options",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "serials": {
                            "type": "InventorySerial",
                            "keyRaw": "serials",

                            "selection": {
                                "fields": {
                                    "number": {
                                        "type": "String",
                                        "keyRaw": "number",
                                        "visible": true
                                    },

                                    "optionValues": {
                                        "type": "InventoryOptionValue",
                                        "keyRaw": "optionValues",

                                        "selection": {
                                            "fields": {
                                                "optionId": {
                                                    "type": "Int",
                                                    "keyRaw": "optionId",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "inventoryId": {
                                                    "type": "Int",
                                                    "keyRaw": "inventoryId",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "optionValues": {
                            "type": "InventoryOptionValue",
                            "keyRaw": "optionValues",

                            "selection": {
                                "fields": {
                                    "optionId": {
                                        "type": "Int",
                                        "keyRaw": "optionId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "inventoryId": {
                                        "type": "Int",
                                        "keyRaw": "inventoryId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "serialized": {
                            "type": "Boolean",
                            "keyRaw": "serialized",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=81b39a5c50d24e99feebc2360cd20af8af325055896cd3d8d95c6f2a278772b0";