<script lang="ts">
	import type { Customer, CustomerType } from 'utility/customer-helper'
	import { hasPermission } from 'utility/permission'
	import { getEventValue } from '@isoftdata/browser-event'
	import { stringToBoolean } from '@isoftdata/utility-string'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Input from '@isoftdata/svelte-input'
	import Table, { Td } from '@isoftdata/svelte-table'
	import Textarea from '@isoftdata/svelte-textarea'

	export let customer: Customer
	export let customerTypeList: CustomerType[] = []
	export let customerChanged: boolean
	export let viewOnlyMode: boolean = false

	let customerCanEditCustomFields = hasPermission('CUSTOMERS_CAN_MANAGE_OPTIONS', customer.store?.id)
	let customerCustomFields = customer.optionValues.map(optionValue => {
		return {
			...optionValue,
			id: optionValue.option.id,
		}
	})

	function optionValueChanged(event: Event, rowIndex: number) {
		const value = getEventValue(event)
		customer.optionValues[rowIndex].value = value
		customerChanged = true
	}
</script>

<div class="form-row">
	<div class="col-12 mb-2">
		<Checkbox
			inline
			type="radio"
			label="Status"
			trueLabel="Active"
			falseLabel="Inactive"
			disabled={viewOnlyMode}
			bind:checked={customer.active}
			on:change={() => (customerChanged = true)}
		/>
	</div>
	<div class="col-12 col-md-4">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="mb-0">Additional Info</h5>
			</div>
			<div class="card-body">
				<Autocomplete
					canAddNew
					label="Customer Type"
					options={customerTypeList}
					disabled={viewOnlyMode}
					bind:value={customer.type}
					on:change={() => (customerChanged = true)}
				/>
				<Input
					label="Driver License Number"
					disabled={viewOnlyMode}
					bind:value={customer.driverLicenseNumber}
					on:change={() => (customerChanged = true)}
				/>
				<Input
					label="Web Page"
					disabled={viewOnlyMode}
					bind:value={customer.webAddress}
					on:change={() => (customerChanged = true)}
				>
					<Button
						slot="append"
						type="button"
						color="primary"
						href={customer.webAddress}
						target="_blank"
						disabled={!customer.webAddress || viewOnlyMode}
					>
						<i class="fas fa-external-link-alt"></i>
					</Button>
				</Input>
				<Input
					readonly
					type="date"
					label="Date Entered"
					value={customer.dateEntered?.toISOString().split('T')[0] ?? new Date().toISOString().split('T')[0]}
				/>
				<Textarea
					label="Notes"
					disabled={viewOnlyMode}
					bind:value={customer.notes}
					on:change={() => (customerChanged = true)}
				/>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-8">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="mb-0">Customer Custom Fields</h5>
			</div>
			<div class="card-body">
				<Table
					responsive
					idProp="id"
					parentStyle="overflow-y: auto; max-height: 55vh;"
					columns={[
						{ property: 'option[label]', name: 'Option' },
						{ property: 'value', name: 'Value' },
						{ property: 'option[showInCustomerList]', name: 'Show In Search', align: 'center', width: '1rem' },
					]}
					bind:rows={customerCustomFields}
					let:row
				>
					<tr>
						<Td property="option[label]">
							{row.option.label}
						</Td>
						<Td property="value">
							{#if row.option.dataType === 'BOOLEAN'}
								<Checkbox
									type="radio"
									showLabel={false}
									checked={stringToBoolean(row.value)}
									disabled={viewOnlyMode || !customerCanEditCustomFields}
									on:change={event => optionValueChanged(event, row.originalIndex)}
								/>
							{:else if row.option.dataType === 'DATE'}
								<Input
									type="date"
									showLabel={false}
									value={row.value}
									disabled={viewOnlyMode || !customerCanEditCustomFields}
									on:change={event => optionValueChanged(event, row.originalIndex)}
								/>
							{:else if row.option.dataType === 'NUMBER'}
								<Input
									type="number"
									showLabel={false}
									value={row.value}
									disabled={viewOnlyMode || !customerCanEditCustomFields}
									on:change={event => optionValueChanged(event, row.originalIndex)}
								/>
							{:else if row.option.dataType === 'CURRENCY'}
								<CurrencyInput
									showLabel={false}
									value={row.value}
									disabled={viewOnlyMode || !customerCanEditCustomFields}
									on:change={event => {
										const value = event.detail.value
										customer.optionValues[row.originalIndex].value = value
										customerChanged = true
									}}
								/>
							{:else}
								<Input
									showLabel={false}
									value={row.value}
									disabled={viewOnlyMode || !customerCanEditCustomFields}
									on:change={event => optionValueChanged(event, row.originalIndex)}
								/>
							{/if}
						</Td>
						<Td property="option[showInCustomerList]">
							<input
								disabled
								type="checkbox"
								checked={row.option.showInCustomerList}
							/>
						</Td>
					</tr>
				</Table>
			</div>
		</div>
	</div>
</div>
