<script lang="ts">
	import type { InventoryCustomFields$result } from '$houdini'
	import type { Column } from '@isoftdata/svelte-table'
	type InventoryCustomFields = InventoryCustomFields$result['inventory']
	interface CustomFieldTableRow {
		field: string
		[key: string]: string
	}

	import { Table, Td } from '@isoftdata/svelte-table'
	import { graphql } from '$houdini'
	import Icon from '@isoftdata/svelte-icon'

	const inventoryCustomFieldsQuery = graphql(`
		query InventoryCustomFields($inventoryId: UInt!) {
			inventory(id: $inventoryId) {
				options {
					id
					name
					rank
				}
				serials {
					number
					optionValues {
						optionId
						value
					}
				}
				optionValues {
					optionId
					value
				}
				serialized
			}
		}
	`)

	export let inventoryId: number
	export let vehicle: boolean = false

	let inventoryCustomFields: InventoryCustomFields | undefined = undefined
	let tableColumns: Column[] = []

	async function loadCustomFields(inventoryId: number) {
		return inventoryId ? (await inventoryCustomFieldsQuery.fetch({ variables: { inventoryId }, policy: 'CacheOrNetwork' }))?.data?.inventory : undefined
	}

	async function loadAndSetCustomFields(inventoryId: number) {
		inventoryCustomFields = await loadCustomFields(inventoryId)
	}

	function computeTableColumns(inventoryCustomFields: InventoryCustomFields | undefined) {
		let columns = [{ property: 'field', name: 'Field' }]
		if (inventoryCustomFields) {
			if (inventoryCustomFields.serialized) {
				for (const serial of inventoryCustomFields.serials) {
					columns.push({ property: serial.number || 'unknownSerial', name: serial.number || 'unknown serial' })
				}
			} else {
				columns.push({ property: 'value', name: 'Value' })
			}
		}
		return columns
	}

	function computeCustomFieldRows(inventoryCustomFields: InventoryCustomFields | undefined): CustomFieldTableRow[] {
		if (inventoryCustomFields) {
			const customFieldsByRank = inventoryCustomFields.options.slice().sort((a, b) => a.rank - b.rank)
			let rows: CustomFieldTableRow[] = []
			if (inventoryCustomFields.serialized) {
				rows = customFieldsByRank.reduce((acc: CustomFieldTableRow[], field) => {
					const serialValues = inventoryCustomFields.serials.reduce((acc, serial) => {
						const optionValueForSerial = serial.optionValues.find(o => o.optionId === field.id)
						acc[serial.number] = optionValueForSerial?.value ?? ''
						return acc
					}, {})
					const customFieldHasSomeValues = Object.values(serialValues).some(value => value)
					if (customFieldHasSomeValues) {
						acc.push({
							field: field.name,
							...serialValues,
						})
					}

					return acc
				}, [])
			} else {
				rows = customFieldsByRank.reduce((acc: CustomFieldTableRow[], field) => {
					const optionValue = inventoryCustomFields.optionValues.find(o => o.optionId === field.id)
					if (optionValue?.value) {
						acc.push({
							field: field.name,
							value: optionValue.value,
						})
					}

					return acc
				}, [])
			}

			return rows
		} else {
			return []
		}
	}

	$: loadAndSetCustomFields(inventoryId)
	$: tableColumns = computeTableColumns(inventoryCustomFields)
	$: tableRows = computeCustomFieldRows(inventoryCustomFields)
</script>

{#if $inventoryCustomFieldsQuery.fetching}
	<Icon
		icon="spinner"
		class="fa-xl fa-spin"
	/>
{:else if tableRows?.length}
	<Table
		rows={tableRows}
		columns={tableColumns}
		let:row={customFieldRow}
		responsive
	>
		<tr>
			{#each tableColumns.map(column => column.property) as prop}
				<Td property={prop}>{customFieldRow[prop]}</Td>
			{/each}
		</tr>
	</Table>
{:else}
	<div
		class="alert alert-info mt-3 mb-0"
		role="alert"
	>
		No Custom Field data available for this {vehicle ? 'vehicle' : 'part'}.
	</div>
{/if}
